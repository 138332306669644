import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
function ArrowDown(props) {
  return __jsx("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    width: "20",
    height: "20",
    fill: "none",
    "aria-hidden": "true"
  }, props), __jsx("path", {
    fill: "currentColor",
    fillRule: "evenodd",
    d: "M5.796 8.796a.75.75 0 1 1 1.06-1.061l2.94 2.94 2.94-2.94a.75.75 0 0 1 1.06 1.06l-3.646 3.647a.5.5 0 0 1-.708 0L5.796 8.796Z",
    clipRule: "evenodd"
  }));
}
export default ArrowDown;